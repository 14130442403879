import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

export default BannerServices => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "angry-black-alsatian.png" }) {
        childImageSharp {
          fluid(maxWidth: 662, maxHeight: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section id='banner' className='style2'>
      <div className='inner'>
        <header className='major'>
          <h1>Web Design Services.</h1>
        </header>
        <div className='content'>
          <p>
            We understand the principles of superlative user interfaces in our
            web design, groundbreaking web development, and closely monitored
            marketing strategies.
            <br />
            <br />
            "The space between, matters."
          </p>
        </div>
        <div className='image'>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt='Angry Black Alsatian'
          />
        </div>
      </div>
    </section>
  )
}
