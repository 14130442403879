import React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Img from 'gatsby-image'
import BannerServices from '../components/BannerServices'
import SEO from '../components/seo'

class Services extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO
          title='Website Services by Black Alsatian'
          description='A brief summary of just some of the website services we offer.'
          keywords={[
            'web design',
            'web development',
            'online marketing',
            'website design',
          ]}
        />

        <BannerServices />

        <div id='main'>
          <section id='one'>
            <div className='inner'>
              <header className='major'>
                <h2>Web Design &amp; Online Marketing.</h2>
              </header>
              <p>
                What happens when an unstoppable force meets an immovable
                object?
              </p>
              <p>Magic. The answer is magic.</p>
              <ul className='actions'>
                <li>
                  <a
                    href='#starstuff'
                    className='button'
                    aria-label='Learn More about colliding forces'
                  >
                    More on the Magic
                  </a>
                </li>
              </ul>
            </div>
          </section>
          <section id='two' className='spotlights'>
            <section id='webdesign' className='services'>
              <OutboundLink
                href='https://www.blackalsatian.co.za/services/website-design/'
                className='image'
                target='_blank'
                title='Web Development Services'
                rel='noopener noreferrer'
                aria-label='Visit Our Main Website To Learn More'
              >
                <Img
                  fluid={data.webDesignPic.childImageSharp.fluid}
                  alt='Website Design'
                />
              </OutboundLink>
              <div className='content'>
                <div className='inner'>
                  <header className='major'>
                    <h3>web design</h3>
                  </header>
                  <p>
                    In quantum physics, an unstoppable force meeting an
                    immovable object isn’t a catastrophe. The atoms slide around
                    each other to form a new reality.
                  </p>
                  <p>
                    This is the principle we apply to our web design. The spaces
                    between matter are as important as matter itself.
                  </p>
                  <p>
                    We understand simple, beautiful web design and smooth
                    functionality.
                  </p>
                  <ul className='actions'>
                    <li>
                      <a
                        href='#onlinemarketing'
                        className='button special'
                        aria-label='Jump To Next Service'
                      >
                        Online Marketing
                      </a>
                    </li>
                    <li>
                      <OutboundLink
                        href='https://www.blackalsatian.co.za/services/website-design/'
                        className='button'
                        target='_blank'
                        title='Web Development Services'
                        rel='noopener noreferrer'
                        aria-label='Visit Our Main Website To Learn More'
                      >
                        More On Our ZA Site
                      </OutboundLink>
                    </li>
                    <li>
                      <Link
                        to='/custom-web-development/'
                        className='button special'
                        aria-label='Find out more about our custom web development'
                        title='Custom website development'
                      >
                        Custom Web Solutions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section id='onlinemarketing' className='services'>
              <OutboundLink
                href='https://www.blackalsatian.co.za/services/online-marketing/'
                className='image'
                target='_blank'
                title='Online Marketing Services'
                rel='noopener noreferrer'
                aria-label='Visit Our Main Website To Learn More'
              >
                <Img
                  fluid={data.marketingPic.childImageSharp.fluid}
                  alt='Online Marketing'
                />
              </OutboundLink>
              <div className='content'>
                <div className='inner'>
                  <header className='major'>
                    <h3>online marketing</h3>
                  </header>
                  <p>
                    If you're looking for solutions, the last thing you need is
                    for an agency to throw your money at the problem and hope it
                    sticks.
                  </p>
                  <p>
                    We throw our expertise and know-how into creating tailored
                    online marketing strategies that WORK for you.
                  </p>
                  <ul className='actions'>
                    <li>
                      <a
                        href='#copywriting'
                        className='button special'
                        aria-label='Jump To Next Service'
                      >
                        Copywriting
                      </a>
                    </li>
                    <li>
                      <OutboundLink
                        href='https://www.blackalsatian.co.za/services/online-marketing/'
                        className='button'
                        target='_blank'
                        title='Digital Marketing Services'
                        rel='noopener noreferrer'
                        aria-label='Visit Our Main Website To Learn More'
                      >
                        More On Our ZA Site
                      </OutboundLink>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section id='copywriting' className='services'>
              <OutboundLink
                href='https://www.blackalsatian.co.za/services/copywriting/'
                className='image'
                target='_blank'
                title='Copywriting Services'
                rel='noopener noreferrer'
                aria-label='Visit Our Main Website To Learn More'
              >
                <Img
                  fluid={data.copyWritingPic.childImageSharp.fluid}
                  alt='Copywriting Services'
                />
              </OutboundLink>
              <div className='content'>
                <div className='inner'>
                  <header className='major'>
                    <h3>copywriting</h3>
                  </header>
                  <p>
                    "Words can be like X-rays if you use them properly - they'll
                    go through anything. You read and you're pierced." Aldous
                    Huxley, Brave New World.
                  </p>
                  <p>"It's toasted." Don Draper, Mad Men.</p>
                  <p>
                    Good copywriting is both a story and a tool. We believe in
                    engaging readers, and leading them gently to a conclusion
                    they firmly believe they reached on their own.
                  </p>
                  <p>
                    The latest innovations in SEO form the basis for our
                    creative and user-friendly web content and marketing copy.
                      
                  </p>
                  <ul className='actions'>
                    <li>
                      <a
                        href='#hosting'
                        className='button special'
                        aria-label='Jump To Next Service'
                      >
                        Hosting
                      </a>
                    </li>
                    <li>
                      <OutboundLink
                        href='https://www.blackalsatian.co.za/services/copywriting/'
                        className='button'
                        target='_blank'
                        title='Copywriting Services'
                        rel='noopener noreferrer'
                        aria-label='Visit Our Main Website To Learn More'
                      >
                        More On Our ZA Site
                      </OutboundLink>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section id='hosting' className='services'>
              <OutboundLink
                href='https://www.blackalsatian.co.za/services/web-hosting/'
                className='image'
                target='_blank'
                title='Web Hosting'
                rel='noopener noreferrer'
                aria-label='Visit Our Main Website To Learn More'
              >
                <Img
                  fluid={data.hostingPic.childImageSharp.fluid}
                  alt='Website Hosting'
                />
              </OutboundLink>
              <div className='content'>
                <div className='inner'>
                  <header className='major'>
                    <h3>hosting &amp; email</h3>
                  </header>
                  <p>
                    We boast a lightning-fast service that will take your user
                    experience out of this world.
                  </p>
                  <p>
                    Our private server hosts ONLY our own clients. With other
                    companies, you get to share with thousands of others. With
                    us, you get a much bigger slice of the pie. Your site loads
                    faster, which makes users happier.
                  </p>
                  <p>
                    Don't want to host a website? Then you can get your personal
                    email, and we'll help you set it up.
                  </p>
                  <ul className='actions'>
                    <li>
                      <a
                        href='#packages'
                        className='button special'
                        aria-label='Jump To Next Service'
                      >
                        Web Design Packages
                      </a>
                    </li>
                    <li>
                      <OutboundLink
                        href='https://www.blackalsatian.co.za/services/web-hosting/'
                        className='button'
                        target='_blank'
                        title='Hosting Service'
                        rel='noopener noreferrer'
                        aria-label='Visit Our Main Website To Learn More'
                      >
                        More On Our ZA Site
                      </OutboundLink>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section id='packages' className='services'>
              <OutboundLink
                href='https://www.blackalsatian.co.za/services/website-design/'
                className='image'
                target='_blank'
                title='Website Design Packages'
                rel='noopener noreferrer'
                aria-label='Visit Our Main Website To Learn More'
              >
                <Img
                  fluid={data.dogPackagesPic.childImageSharp.fluid}
                  alt='Budget Website Packages'
                />
              </OutboundLink>
              <div className='content'>
                <div className='inner'>
                  <header className='major'>
                    <h3>budget website packages</h3>
                  </header>
                  <p>
                    Some sites are bigger than others. If you need a full-blown
                    Content Management System or eCommerce website, we're your
                    guys.
                  </p>
                  <p>
                    But we love our smaller websites too. Simple, beautiful web
                    design showcases your product, service or self – all of the
                    style at a more affordable price.
                  </p>

                  <ul className='actions'>
                    <li>
                      <a
                        href='#contact'
                        className='button special'
                        aria-label='Contact Us'
                      >
                        Get Started
                      </a>
                    </li>
                    <li>
                      <OutboundLink
                        href='https://www.blackalsatian.co.za/services/website-design/'
                        className='button'
                        target='_blank'
                        title='Website Packages'
                        rel='noopener noreferrer'
                        aria-label='Visit Our Main Website To Learn More'
                      >
                        More On Our ZA Site
                      </OutboundLink>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </section>
          <div id='starstuff' className='inner'>
            <p>
              “What happens when an unstoppable force meets an immovable
              object?” This is an ancient paradox that arises from the
              philosophies of many cultures.
            </p>

            <p>
              In Greek mythology, the paradox gave birth to Sirius, the Dog Star
              – brightest in the Southern sky.
            </p>
            <blockquote>
              The Formation of the Dog Star - The story of Laelaps and the
              Teumessian Fox is a tale of a paradox that appears in Greek
              mythology. Laelaps was a hound destined to catch whatever it
              chased, whilst the Teumessian Fox was destined to never be caught.
              The chase went on but Laelaps could not close on the Teumessian
              Fox, nor could the Teumessian Fox get clear of the hound. <br />
              <br />
              Zeus looked down from Mount Olympus and observed the paradox that
              was taking place. He decided that he could not let the chase
              continue on earth, and so changed both animals into stone. Zeus
              then transformed the pair into two constellations, Canis Major
              (Laelaps) and Canis Minor (the Teumessian Fox). The chase
              continues in the night sky.
              <br />
              <br />
              The Dog Star forms part of Canis Major and is seen most brightly
              in the Southern hemisphere.
            </blockquote>
            <p>
              <strong>tl,dr:</strong> When dynamic vision and experience meet
              solid ethics and values, magic is the result.
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Services

export const query = graphql`
  query {
    webDesignPic: file(relativePath: { eq: "web-designer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marketingPic: file(relativePath: { eq: "marketing.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    copyWritingPic: file(relativePath: { eq: "copywriting.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hostingPic: file(relativePath: { eq: "hosting.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dogPackagesPic: file(relativePath: { eq: "budget-packages.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
